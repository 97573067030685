import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Modal, Form, FormGroup, FormControl, Button, Card, Spinner } from "react-bootstrap";
import { FaEye, FaEyeSlash, FaSpinner } from 'react-icons/fa';
import Select from 'react-select';
import { Input, Label } from "reactstrap";
import { AccountContext } from 'components/Account/Account';
import empresas from 'api/empresas';
import webUserManagment from "api/webUserManagment";
import DataTable from "react-data-table-component";
import permissionsManagment from "api/permissionsManagment";

function Companies() {
    const { userSession, getSession } = useContext(AccountContext);
    const [token, setToken] = useState(null);
    const [apiError, setApiError] = useState(null); // Mensaje de error de la API
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [empresaName, setEmpresaName] = useState(null);
    const [rutEmpresa, setRutEmpresa] = useState(null);

    const [empresa, setEmpresa] = useState(null);

    const [empresaList, setEmpresaList] = useState([]);
    const [filter, setFilter] = useState('');
    const [dataFiltered, setDataFiltered] = useState([]);
    const [messageTable, setMessageTable] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [permissionsByTenant, setPermissionsByTenant] = useState([]);
    const [permissionsByTenantOriginal, setPermissionsByTenantOriginal] = useState([]);
    const [dynamicSelects, setDynamicSelects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successModalMessage, setSuccessModalMessage] = useState(false);



    const handleShowEditModal = (e) => {
        setIsLoading(true);
        setFormError("");
        setEmpresa(e);
        setEmpresaName(e.name);
        setRutEmpresa(e.rut);
        setShowEditModal(true);

        permissionsManagment.getPermissionsByTenant(e.tenant_id, token).then((response) => {
            console.log("Permisos activados en el tenant:", response.data);

            // Inicializa los selectores dinámicos con los permisos activados
            const initialSelects = response.data.length > 0
                ? response.data.map((permission) => ({ value: permission.permission_id }))
                : [];

            setPermissionsByTenantOriginal(response.data); // Permisos activados en el tenant
            const tenantPermissions = response.data.map((item) => ({
                permission_id: item.permission_id,
            }));
            console.log('initial select:', initialSelects);
            setPermissionsByTenant(tenantPermissions);
            setDynamicSelects(initialSelects);    // Selectores iniciales
            setIsLoading(false);
        }).catch((error) => {
            console.error("Error al obtener permisos del tenant:", error);
            setIsLoading(false);

            // Si hay un error, inicializa con un selector vacío
            setDynamicSelects([]);
        });
    };



    const handleShowDeleteModal = (user) => {
        // console.log(user)
        setCurrentUserId(user.id);
        setUsername(user.username);
        setShowDeleteModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        // setFormError("");

    }

    const handleEditEmpresa = (e) => {


        setIsLoading(true);
        console.log("original:", permissionsByTenantOriginal)
        console.log("mod:", permissionsByTenant)
        const originalIds = permissionsByTenantOriginal.map((e) => e.permission_id).sort();
        const currentIds = permissionsByTenant.map((e) => e.permission_id).sort();

        console.log(originalIds)
        console.log(currentIds);


        const areEqual = JSON.stringify(originalIds) === JSON.stringify(currentIds);

        if (!areEqual) {
            console.log("Se realizaron cambios, hay que actualizar");

            permissionsManagment.putPermissionsByTenant(empresa.tenant_id, permissionsByTenant, token).then((response) => {
                console.log(response);
                setSuccessModalMessage(" Los permisos y restricciones se han actualizado correctamente.")
                setShowSuccessModal(true); // Mostrar el modal de éxito
                setIsLoading(false);
                setShowEditModal(false);

            }).catch((error) => {
                console.log(error);
                setShowErrorModal(true);
                setShowEditModal(false);
                setApiError("Error al guardar los permisos");

            })

        }




    }

    const handleSelectChange = (index, value) => {
        // Verifica si el permiso ya fue seleccionado en otro selector
        const isDuplicate = dynamicSelects.some((select, i) => i !== index && select.value === value);

        if (isDuplicate) {
            console.log("Es duplicado");
            console.log("dyn:", dynamicSelects);

            // Restablece el valor del último selector a ""
            const newDynamicSelects = [...dynamicSelects];
            newDynamicSelects[index].value = ""; // Restablece el valor del select actual
            setDynamicSelects(newDynamicSelects);

            // Configura el mensaje de error
            setFormError("Este permiso ya ha sido agregado.");
            return; // Salir de la función para no procesar más
        }
        setFormError("");

        // Si no es duplicado, actualiza el estado
        const newDynamicSelects = [...dynamicSelects];
        newDynamicSelects[index].value = value;
        setDynamicSelects(newDynamicSelects);
        const tenantPermissions = newDynamicSelects.map((item) => ({
            permission_id: item.value,
        }));
        setPermissionsByTenant(tenantPermissions);
    };

    const addNewSelect = () => {

        setDynamicSelects((prevSelects) => [...prevSelects, { value: "" }]);
        console.log(dynamicSelects)
    };

    const removeSelect = (index) => {
        setFormError("");
        const newDynamicSelects = dynamicSelects.filter((_, i) => i !== index);
        setDynamicSelects(newDynamicSelects);
        const tenantPermissions = newDynamicSelects.map((item) => ({
            permission_id: item.value,
        }));
        setPermissionsByTenant(tenantPermissions);
    };


    useEffect(() => {

        getSession().then((session) => {
            // return session.idToken.jwtToken
            setToken(session.idToken.jwtToken);
            empresas.getEmpresas(session.idToken.jwtToken).then((response) => {
                const sortedcompanies = response.data.sort((a, b) => {
                    const companieA = a.name?.toLowerCase() || ""; 
                    const companieB = b.name?.toLowerCase() || "";
                    return companieA.localeCompare(companieB);
                });

                setEmpresaList(sortedcompanies);
                setDataFiltered(sortedcompanies);
            }).catch((error) => {
                console.error(error);
            });

            permissionsManagment.getPermissions(session.idToken.jwtToken).then((response) => {
                setPermissions(response.data);
                // console.log('available permissions:', response.data);

            }).catch((error) => {
                console.log(error)
            })
        });


    }, []);


    useEffect(() => {
        const aux = empresaList.filter((element) => {
            const { name, rut } = element;
            return (
                (name && name.toLowerCase().trim().includes(filter.toLowerCase().trim())) ||
                (rut && rut.toString().includes(filter.toLocaleLowerCase().trim()))

            );
        });
        setDataFiltered(aux);
    }, [filter]);

    const columns = [
        {
            name: "Nombre",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Rut",
            selector: (row) => row.rut,
            sortable: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <div className="d-flex justify-content-between">
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleShowEditModal(row)}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleShowDeleteModal(row)}
                    >
                        Eliminar
                    </Button>
                </div>
            ),
        },
    ];

    const paginationOpciones = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsText: 'Todos'
    }

    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                            <Card.Title as="h4">Gestión de Empresas</Card.Title>
                        </Card.Header>
                        <Card.Body>


                            <Card.Body className="table-full-width table-responsive px-0">

                                {messageTable &&
                                    <p>{messageTable}</p>
                                }
                                {empresaList.length > 0 ? (
                                    <>
                                        <Input
                                            name="search"
                                            value={filter}
                                            autoComplete="off"
                                            placeholder="Filtrar"
                                            className="mb-3"
                                            onChange={(e) => { setFilter(e.target.value); }}
                                        />
                                        <DataTable
                                            className="-striped -highlight"
                                            fixedHeader={false}
                                            columns={columns}
                                            data={dataFiltered}
                                            pagination
                                            paginationComponentOptions={paginationOpciones}
                                        />
                                    </>
                                ) : (
                                    <div className="no-data-message">
                                        No hay información disponible.
                                    </div>
                                )}
                            </Card.Body>


                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal para editar empresa */}
            <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header>
                    <Modal.Title>Editar empresa</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form>
                        {/* Información General */}
                        <div>
                            <h5>Información de la Empresa</h5>
                            <FormGroup>
                                <label>Nombre de Empresa</label>
                                <FormControl
                                    type="text"
                                    value={empresaName}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Rut</label>
                                <FormControl
                                    type="text"
                                    value={rutEmpresa}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormGroup>
                        </div>

                        <hr />

                        {/* Sección de Permisos */}
                        <div>
                            <h5>Gestión de Permisos</h5>

                            {!isLoading &&
                                <>
                                    {dynamicSelects.length > 0 ? (
                                        dynamicSelects.map((select, index) => (
                                            <FormGroup key={index}>
                                                <label>Seleccionar permiso {index + 1}</label>
                                                <div style={{ display: "flex", gap: "8px" }}>
                                                    <FormControl
                                                        as="select"
                                                        value={select.value}
                                                        onChange={(e) => handleSelectChange(index, e.target.value)}
                                                    >
                                                        <option value="">Seleccionar permiso</option>
                                                        {permissions.map((option) => (
                                                            <option key={option.permission_id} value={option.permission_id}>
                                                                {option.permission_name}
                                                            </option>
                                                        ))}
                                                    </FormControl>
                                                    {index >= 0 && (
                                                        <Button variant="danger" onClick={() => removeSelect(index)}>
                                                            -
                                                        </Button>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        ))
                                    ) : (
                                        <p>No hay permisos activados. Agrega uno.</p>
                                    )}

                                    {formError && <p style={{ color: "red" }}>{formError}</p>}
                                    <Button variant="success" onClick={addNewSelect}>
                                        + Agregar permiso
                                    </Button>
                                </>
                            }

                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleEditEmpresa}>
                        Guardar Cambios
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Carga */}
            <Modal show={isLoading} centered backdrop="static" keyboard={false}>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando...</p>
                </Modal.Body>
            </Modal>

            {/* Modal de Error */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{apiError}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowErrorModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>Éxito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{successModalMessage}</p>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => setShowSuccessModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>




        </Container>
    );
};

export default Companies;

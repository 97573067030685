import apiurl from './apiurl';
const axios = require('axios');

const permissionsManagment = {
  // Función para obtener nombres de empresas
  getPermissions: async (idToken) => {
    return axios({
      method: 'GET',
      url: apiurl + 'permissions',
      headers: {
        'content-type': 'application/json',
        'Authorization': "Bearer " + idToken
      },
      crossdomain: true,
    });
  }
,
 // Función para obtener nombres de empresas
 getPermissionsByTenant: async (tenant_id,idToken) => {
  return axios({
    method: 'GET',
    url: apiurl + `permissions/${tenant_id}`,
    headers: {
      'content-type': 'application/json',
      'Authorization': "Bearer " + idToken
    },
    crossdomain: true,
  });
},
 // Función para obtener nombres de empresas
 putPermissionsByTenant: async (tenant_id,permissions,idToken) => {
  return axios({
    method: 'PUT',
    url: apiurl + `permissions/${tenant_id}`,
    headers: {
      'content-type': 'application/json',
      'Authorization': "Bearer " + idToken
    },
    data: permissions,
    crossdomain: true,
  });
},
getPermissionsByUser: async (cognito_user_id,idToken) => {
  return axios({
    method: 'GET',
    url: apiurl + `permissions/users/${cognito_user_id}`,
    headers: {
      'content-type': 'application/json',
      'Authorization': "Bearer " + idToken
    },
    crossdomain: true,
  });
},
putPermissionsByUser: async (cognito_user_id,body,idToken) => {
  return axios({
    method: 'PUT',
    url: apiurl + `permissions/users/${cognito_user_id}`,
    headers: {
      'content-type': 'application/json',
      'Authorization': "Bearer " + idToken
    },
    data: JSON.stringify(body),
    crossdomain: true,
  });
},



};

export default permissionsManagment;
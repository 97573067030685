import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Modal, Form, FormGroup, FormControl, Button, Card, Spinner } from "react-bootstrap";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Select from 'react-select';
import { Input, Label } from "reactstrap";
import { AccountContext } from 'components/Account/Account';
import empresas from 'api/empresas';
import webUserManagment from "api/webUserManagment";
import DataTable from "react-data-table-component";
import permissionsManagment from "api/permissionsManagment";


function WebUsers() {
    const { userSession, getSession } = useContext(AccountContext);
    const [token, setToken] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showApiErrorModal, setShowApiErrorModal] = useState(false); // Modal para errores de la API
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPermissionsModal, setShowPermissionsModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [apiError, setApiError] = useState(null); // Mensaje de error de la API
    const [empresa, setEmpresa] = useState(null);
    const [empresaSearch, setEmpresaSearch] = useState(null);
    const [empresaList, setEmpresaList] = useState([]);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [options, setOptions] = useState([]);
    const [formError, setFormError] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Nuevo estado para la carga
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState('');
    const [dataFiltered, setDataFiltered] = useState([]);
    const [messageTable, setMessageTable] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [resetPassword, setResetPassword] = useState(false);
    const [dynamicSelects, setDynamicSelects] = useState([]);
    const [permissionsByTenant, setPermissionsByTenant] = useState([]);
    const [permissionsByUser, setPermissionsByUser] = useState([]);
    const [permissionsByUserOriginal, setPermissionsByUserOriginal] = useState([]);
    const [jsonRestrictions, setJsonRestrictions] = useState("");
    const [jsonRestrictionsOriginal, setJsonRestrictionsOriginal] = useState("");
    const [JsonError, setJsonError] = useState("");
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successModalMessage, setSuccessModalMessage] = useState(false);
    


    const handleShowModal = () => {
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setShowPassword(false);
        setShowConfirmPassword(false);
        setShowModal(true)
    };
    const handleCloseModal = () => {
        setShowModal(false)
        setCurrentUserId(null);
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setFormError(null)
    };
    const handleShowEditModal = (user) => {
        setCurrentUserId(user.id);
        setUsername(user.username);
        setEmail(user.email);
        setShowPassword(false);
        setShowConfirmPassword(false);
        setShowEditModal(true);
    };

    const handleShowPermissionsModal = (user) => {
        setCurrentUserId(user.id);
        setUsername(user.username);
        setEmail(user.email);
        setShowPermissionsModal(true);
        setJsonError(null);
        setJsonRestrictions("")
        setIsLoading(true);

        console.log(user);
        permissionsManagment.getPermissionsByTenant(user.tenant_id, token).then((response) => {
            console.log(response.data)

            setPermissionsByTenant(response.data);

        }).catch((error) => {
            console.log(error)
        })

        permissionsManagment.getPermissionsByUser(user.id, token).then((response) => {

            // console.log(response.data)
            const permissions = response.data.userPermissions;
            setJsonRestrictions(response.data.userRestrictions);
            setJsonRestrictionsOriginal(response.data.userRestrictions)
            setPermissionsByUserOriginal(permissions);
            const userPermissions = permissions.map((item) => ({
                tenant_permission_id: item.tenant_permission_id,
            }));

            setPermissionsByUser(userPermissions);
            const sortedpermission = permissions.sort((a, b) => {
                const permissionA = a.permission_name?.toLowerCase() || "";
                const permissionB = b.permission_name?.toLowerCase() || "";
                return permissionA.localeCompare(permissionB);
            });
            const initialSelects = sortedpermission.length > 0
                ? permissions.map((permission) => ({ value: permission.tenant_permission_id }))
                : [];
            console.log('initial select:', initialSelects);
            setDynamicSelects(initialSelects);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        })
    };



    const handleClosePermissionsModal = () => {
        setShowPermissionsModal(false);
        setCurrentUserId(null);
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setFormError(null)
    }
    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setCurrentUserId(null);
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setFormError(null)
    }
    const handleShowDeleteModal = (user) => {
        // console.log(user)
        setCurrentUserId(user.id);
        setUsername(user.username);
        setShowDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleSelectChange = (e) => {
        setEmpresa(e.value);
    };



    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (confirmPassword !== e.target.value) {
            setFormError("Las contraseñas no coinciden");
        } else {
            setFormError(null);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (password !== e.target.value) {
            setFormError("Las contraseñas no coinciden");
        } else {
            setFormError(null);
        }
    };

    const createWebUser = async (e) => {
        e.preventDefault();

        // Validación del formulario
        if (!username || !email || !password || !empresa) {
            setFormError("Todos los campos son obligatorios");
            return;
        }

        if (password !== confirmPassword) {
            setFormError("Las contraseñas no coinciden");
            return;
        }

        setFormError(null); // Limpiar errores de validación
        const body = { tenant_id: empresa, email, username, password }
        // const idToken = await getSession().then((session) => { return session.idToken.jwtToken });
        setIsLoading(true); // Activar el estado de carga


        webUserManagment.createWebUser(body, token)
            .then((response) => {
                // console.log("Usuario creado con éxito:", response);
                setSuccessModalMessage("Usuario creado con éxito.")
                setShowSuccessModal(true);
                setShowModal(false);
                setIsLoading(false);
                fetchUsers();
            })
            .catch((error) => {
                console.error("Error al crear usuario:", error);
                setApiError(error.response?.data?.message || "Error desconocido al crear el usuario");
                setShowApiErrorModal(true);
                setIsLoading(false);

            });
    }

    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        if (empresaSearch == null) {
            return;
        }
        setMessageTable(null);
        setIsLoading(true);

        fetchUsers();
    }

    const handleEditUser = async () => {
        setIsLoading(true);
        // Crear el cuerpo de la solicitud solo con los campos necesarios
        const body = {
            resetPassword: false,
            username: username
        };
        if (email) body.email = email; // Actualiza solo si el correo ha cambiado
        if (resetPassword) {
            if (!password) {
                setFormError("Debe ingresar una contraseña temporal para restablecerla.");
                setIsLoading(false);
                return;
            }
            body.resetPassword = resetPassword;
            body.password = password; // Añade contraseña temporal si está activado el reset
        }

        // Si no hay cambios relevantes, evita hacer la solicitud
        if (Object.keys(body).length === 0) {
            setFormError("No hay cambios para guardar.");
            setIsLoading(false);
            return;
        }

        console.log(currentUserId, body);
        // return;
        webUserManagment
            .updateWebUser(currentUserId, body, token)
            .then(() => {
                setShowEditModal(false);
                setIsLoading(false);
                fetchUsers(); // Recargar usuarios
            })
            .catch((error) => {
                console.error("Error al editar usuario:", error);
                setApiError(error.response?.data?.message || "Error desconocido al actualizar el usuario");
                setShowApiErrorModal(true);
                setIsLoading(false);
            });
    };

    const handleDeleteUser = async () => {
        setIsLoading(true);
        webUserManagment
            .deleteWebUser(currentUserId, token)
            .then(() => {
                setSuccessModalMessage("Usuario eliminado con éxito.")
                setShowSuccessModal(true);
                setShowDeleteModal(false);
                setIsLoading(false);
                fetchUsers(); // Recargar usuarios
            })
            .catch((error) => {
                console.error("Error al eliminar usuario:", error);
                setApiError(error.response?.data?.message || "Error desconocido al eliminar el usuario");
                setShowApiErrorModal(true);
                setIsLoading(false);
                setShowDeleteModal(false);
            });
    };

    const handleSavePermissions = (e) => {

        setIsLoading(true);

        // Comparar permisos
        const originalIds = permissionsByUserOriginal.map((e) => e.tenant_permission_id).sort();
        const currentIds = permissionsByUser.map((e) => e.tenant_permission_id).sort();
        const arePermissionsEqual = JSON.stringify(originalIds) === JSON.stringify(currentIds);

        // Comparar restricciones
        const originalRestrictions = JSON.stringify(jsonRestrictionsOriginal); // Debes tener un estado con el original
        const currentRestrictions = JSON.stringify(jsonRestrictions);
        const areRestrictionsEqual = originalRestrictions === currentRestrictions;

        // Decidir si hay cambios
        const areEqual = arePermissionsEqual && areRestrictionsEqual;

        const body = { permissions: permissionsByUser, restrictions: jsonRestrictions };

        if (!areEqual) {
            console.log("Se realizaron cambios, hay que actualizar");
    
            permissionsManagment.putPermissionsByUser(currentUserId, body, token).then((response) => {
                console.log(response);
                setIsLoading(false);
                setShowPermissionsModal(false);
                setSuccessModalMessage(" Los permisos y restricciones se han actualizado correctamente.")
                setShowSuccessModal(true); // Mostrar el modal de éxito
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
                setShowErrorModal(true);
                setApiError("Error al guardar los permisos");
            });
        } else {
            console.log("No hay cambios para actualizar.");
            setIsLoading(false);
            setShowPermissionsModal(false);
        }
    }

    const handleSelectPermissionsChange = (index, value) => {
        const isDuplicate = dynamicSelects.some((select, i) => i !== index && select.value === value);

        if (isDuplicate) {
            setFormError("Este permiso ya ha sido agregado.");
            return;
        }

        setFormError(null);

        // Actualizar el estado de dynamicSelects
        const updatedDynamicSelects = [...dynamicSelects];
        updatedDynamicSelects[index].value = value;
        setDynamicSelects(updatedDynamicSelects);

        // Actualizar el estado de permissionsByUser
        const updatedPermissions = updatedDynamicSelects.map((select) => ({
            tenant_permission_id: select.value,
        }));
        setPermissionsByUser(updatedPermissions);
    };


    const addNewSelect = () => {
        setDynamicSelects((prevSelects) => [...prevSelects, { value: "" }]);
    };


    const removeSelect = (index) => {
        setFormError("");
        const newDynamicSelects = dynamicSelects.filter((_, i) => i !== index);
        setDynamicSelects(newDynamicSelects);
        const userPermissions = newDynamicSelects.map((item) => ({
            tenant_permission_id: item.value,
        }));
        setPermissionsByUser(userPermissions);
    };


    const fetchUsers = async () => {
        webUserManagment.getWebUsers(token, empresaSearch)
            .then((response) => {
                if (response.data.length < 1) {
                    setMessageTable('No hay registros');
                }


                const sortedUsers = response.data.sort((a, b) => {
                    const usernameA = a.username?.toLowerCase() || "";
                    const usernameB = b.username?.toLowerCase() || "";
                    return usernameA.localeCompare(usernameB);
                });

                setUsers(sortedUsers);
                setDataFiltered(sortedUsers);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error al obtener usuarios:", error);
                setIsLoading(false);
            });
    };

    const handleJsonChange = (e) => {

        const input = e.target.value;
        setJsonRestrictions(input);
        if (input.trim() === "") {
            setJsonError(null);
            return;
        }

        try {
            JSON.parse(input);
            setJsonError(null); // Si es válido, limpia el error
        } catch (error) {
            setJsonError("El JSON no es válido."); // Si hay error, muestra mensaje
        }
    };




    useEffect(() => {

        getSession().then((session) => {
            // return session.idToken.jwtToken
            setToken(session.idToken.jwtToken);
            empresas.getEmpresas(session.idToken.jwtToken).then((response) => {
                setEmpresaList(response.data);
            }).catch((error) => {
                console.error(error);
            });
        });

    }, []);

    useEffect(() => {
        const selectOptions = empresaList
            // .filter((element) => element.rut !== 1)
            .map((element) => ({
                value: element.tenant_id,
                label: `${element.rut} - ${element.name}`
            }));
        setOptions(selectOptions);
    }, [empresaList]);

    useEffect(() => {
        const aux = users.filter((element) => {
            const { username, email } = element;
            return (
                (username && username.toLowerCase().trim().includes(filter.toLowerCase().trim())) ||
                (email && email.toLowerCase().trim().includes(filter.toLocaleLowerCase().trim()))

            );

        });
        setDataFiltered(aux);
    }, [filter]);

    const columns = [
        {
            name: "Usuario",
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Acciones",
            cell: (row) => (
                <div className="d-flex justify-content-between">
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleShowEditModal(row)}
                    >
                        Editar
                    </Button>
                    <Button
                        variant="secodary"
                        size="sm"
                        onClick={() => handleShowPermissionsModal(row)}
                    >
                        Modificar Permisos
                    </Button>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleShowDeleteModal(row)}
                    >
                        Eliminar
                    </Button>
                </div>
            ),
        },
    ];

    const paginationOpciones = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsText: 'Todos'
    }

    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <Card className="strpied-tabled-with-hover">
                        <Card.Header>
                            <Card.Title as="h4">Gestión de Usuarios</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-end mb-4">
                                <Button variant="primary" onClick={handleShowModal}>
                                    Agregar Usuario
                                </Button>
                            </div>
                            <Form>
                                <FormGroup>
                                    <Label>
                                        Empresa
                                    </Label>
                                    <Col xs="12" sm="12" md="8">
                                        <Select options={options}
                                            // isLoading={loadingOptions}        
                                            onChange={(e) => setEmpresaSearch(e.value)}
                                            placeholder='Empresa' />
                                    </Col>
                                </FormGroup>
                                <Button variant="secondary" onClick={handleSubmitSearch}>Consultar Usuarios</Button>

                            </Form>

                            <Card.Body className="table-full-width table-responsive px-0">

                                {messageTable &&
                                    <p>{messageTable}</p>
                                }
                                {users.length > 0 &&
                                    <>
                                        <Input name="search" value={filter} autoComplete="off" placeholder="Filtrar" className="mb-3" onChange={(e) => { setFilter(e.target.value) }} />
                                        <DataTable
                                            className="-striped -highlight"
                                            fixedHeader={false}
                                            columns={columns}
                                            data={dataFiltered}
                                            pagination
                                            paginationComponentOptions={paginationOpciones}
                                        // actions={actionsMemo}
                                        />
                                    </>}
                            </Card.Body>


                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Modal de creación de usuario */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header>
                    <Modal.Title>Agregar Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={createWebUser}>
                        <FormGroup>
                            <label>Empresa</label>
                            <Select
                                options={options}
                                onChange={handleSelectChange}
                                placeholder="Seleccione Empresa"
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Nombre de Usuario</label>
                            <FormControl
                                type="text"
                                placeholder="Ingrese nombre de usuario"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                autoComplete="off"
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Correo Electrónico</label>
                            <FormControl
                                type="email"
                                placeholder="Ingrese correo electrónico"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Contraseña</label>
                            <div className="input-group">
                                <FormControl
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Ingrese contraseña"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    autoComplete="new-password"
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <label>Confirmar Contraseña</label>
                            <div className="input-group">
                                <FormControl
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirme contraseña"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    autoComplete="off"
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                            </div>
                        </FormGroup>
                        {formError && <p style={{ color: "red" }}>{formError}</p>}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={createWebUser}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de error de la API */}
            <Modal show={showApiErrorModal} onHide={() => setShowApiErrorModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{apiError}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowApiErrorModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para editar usuario */}
            <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header>
                    <Modal.Title>Editar Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup>
                            <label>Nombre de Usuario</label>
                            <FormControl
                                type="text"
                                value={username}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Email</label>
                            <FormControl
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex align-items-center">
                                <Input
                                    type="checkbox"
                                    checked={resetPassword}
                                    onChange={(e) => setResetPassword(e.target.checked)}
                                />
                                <Label className="ml-2">Restablecer Contraseña</Label>
                            </div>
                        </FormGroup>
                        {resetPassword && (
                            <FormGroup>
                                <label>Contraseña Temporal</label>
                                <div className="input-group">
                                    <FormControl
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Ingrese contraseña temporal"
                                        value={password}
                                        onChange={handlePasswordChange}
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </Button>
                                </div>
                                <Form.Text className="text-muted">
                                    El usuario deberá cambiar esta contraseña en su próximo inicio de sesión.
                                </Form.Text>
                            </FormGroup>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleEditUser}>
                        Guardar Cambios
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* Modal para modificar permisos */}
            <Modal show={showPermissionsModal} onHide={handleClosePermissionsModal}>
                <Modal.Header>
                    <Modal.Title>Editar permisos</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        {/* Información General */}
                        <div>
                            <h5>Información del usuario</h5>
                            <FormGroup>
                                <label>Nombre usuario</label>
                                <FormControl
                                    type="text"
                                    value={username}
                                    readOnly
                                />
                            </FormGroup>
                            <FormGroup>
                                <label>Email</label>
                                <FormControl
                                    type="email"
                                    value={email}
                                    readOnly
                                />
                            </FormGroup>
                        </div>

                        <hr />


                        {/* Sección de Permisos */}
                        <div>
                            <h5>Gestión de Permisos</h5>



                            {!isLoading &&
                                <>
                                    {/* Sección de JSON de permisos */}
                                    <div>

                                        <FormGroup>
                                            <label>Ingresar restricciones en formato JSON</label>
                                            <FormControl
                                                as="textarea"
                                                rows={5}
                                                value={jsonRestrictions}
                                                onChange={handleJsonChange}
                                                placeholder='{"key": "value"}'
                                                style={{
                                                    fontFamily: "monospace",
                                                    resize: "none"
                                                }}
                                            />

                                            {JsonError && <p style={{ color: "red" }}>{JsonError}</p>}
                                        </FormGroup>
                                    </div>
                                    {dynamicSelects.length > 0 ? (
                                        dynamicSelects.map((select, index) => (
                                            <FormGroup key={index}>
                                                <label>Seleccionar permiso {index + 1}</label>
                                                <div style={{ display: "flex", gap: "8px" }}>
                                                    <FormControl
                                                        as="select"
                                                        value={select.value}
                                                        onChange={(e) => handleSelectPermissionsChange(index, e.target.value)}
                                                    >
                                                        <option value="">Seleccionar permiso</option>
                                                        {permissionsByTenant.map((option) => (
                                                            <option key={option.tenant_permission_id} value={option.tenant_permission_id}>
                                                                {option.permission_name}
                                                            </option>
                                                        ))}
                                                    </FormControl>
                                                    {index >= 0 && (
                                                        <Button variant="danger" onClick={() => removeSelect(index)}>
                                                            -
                                                        </Button>
                                                    )}
                                                </div>
                                            </FormGroup>
                                        ))
                                    ) : (
                                        <p>No hay permisos activados. Agrega uno.</p>
                                    )}

                                    {formError && <p style={{ color: "red" }}>{formError}</p>}
                                    <Button variant="success" onClick={addNewSelect}>
                                        + Agregar permiso
                                    </Button>
                                </>
                            }

                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePermissionsModal}>
                        Cerrar
                    </Button>
                    <Button
                        variant="light"
                        onClick={handleSavePermissions}
                        disabled={!!JsonError}
                        className={`text-${JsonError ? "secondary" : "primary"}`}
                        style={{
                            marginTop: "10px",
                            padding: "10px 20px",
                        }}
                    >
                        Guardar Cambios
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal para confirmar eliminación */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar al usuario {username}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDeleteUser}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de carga */}
            <Modal show={isLoading} centered>
                <Modal.Body className="text-center">
                    <Spinner animation="border" />
                    <p>Cargando...</p>
                </Modal.Body>
            </Modal>

            {/* Modal de Error */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{apiError}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowErrorModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>Éxito</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{successModalMessage}</p>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => setShowSuccessModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};

export default WebUsers;

import apiurl from './apiurl';
const axios = require('axios');

const consultaFolio= async (params,idToken) =>{
    console.log(params,idToken);
    
    
        try {
            const response = await axios({
                'method':'GET',
                'url':apiurl +'consultaestadofolio',
                'headers': {
                    'content-type':'application/json',
                    'Authorization':"Bearer "+idToken
                },
                'crossdomain': true,
                'params': {
                    ...params
                }
            })
            return response.data;
        } catch (error) {
            throw error;
        }

    
    
}
export default consultaFolio;
import apiurl from './apiurl';
const axios = require('axios');

const webUserManagment = {
  // Función para obtener nombres de empresas
  getWebUsers: async (idToken,empresa) => {
    return axios({
      method: 'GET',
      url: apiurl + 'webuser',
      headers: {
        'content-type': 'application/json',
        'Authorization': "Bearer " + idToken
      },
      'params': {
        tenant_id:empresa
    },
      crossdomain: true,
    });
  },

  createWebUser: async (body, idToken) => {
    return axios({
      method: 'POST',
      url: apiurl + 'webuser',
      headers: {
        'content-type': 'application/json',
        'Authorization': "Bearer " + idToken
      },
      data: body,
      crossdomain: true,
    });
  },
  updateWebUser: async (user_id,body, idToken) => {
    return axios({
      method: 'PUT',
      url: apiurl + 'webuser',
      headers: {
        'content-type': 'application/json',
        'Authorization': "Bearer " + idToken
      },
      params:{user_id},
      data: body,
      crossdomain: true,
    });
  },
  deleteWebUser: async (user_id, idToken) => {
    return axios({
      method: 'DELETE',
      url: apiurl + 'webuser',
      headers: {
        'content-type': 'application/json',
        'Authorization': "Bearer " + idToken
      },
      params:{user_id},
      crossdomain: true,
    });
  },


};

export default webUserManagment;